import React from "react";
import VerticallyCenteredModal from './VerticallyCenteredModal'
import HeaderDesign from "../components/design/Header"
import handleAddBasic from './templates/Basic'
import { ComponentDisplayBox } from './utils/ComponentDisplayBox'

export default function BasicTemplate() {
    const [modalShow, setModalShow] = React.useState(false);
    const [component, setComponent] = React.useState(false);
    return (
        <React.Fragment>
            <div className="d-flex flex-wrap">
                <ComponentDisplayBox
                    title="Basic"
                    description="Want to get up and running quickly? Send your ideas out to friends and family or colleagues first to get feedback about your idea? Then use the basic setup to rapidly place your ideas on the web."
                    hasOptions={true}
                    optionsHn={{ "setModalShow" : setModalShow, "setComponent": setComponent, "component": HeaderDesign}}
                    addHn={handleAddBasic}/>
            </div>

            <VerticallyCenteredModal
                heading="Component"
                description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                show={modalShow}
                onHide={() => setModalShow(false)}
                component={component}
            />

        </React.Fragment>
    );
}