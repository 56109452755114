const config = {
    STRIPE_KEY: "pk_test_51IFQBsA0I847WjkhjKHt8IXtqtZjY7v1GbySuwbUG5QEkSAzdztwdzOiYYoT8asEqRaBp0gCjVY5w52d2ixpzEzU00VivCGojQ",
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "fa4-app-upload",
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://d7ss7cl8lg.execute-api.us-east-1.amazonaws.com/prod",
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_Dc41NMlTK",
      APP_CLIENT_ID: "6lhquma5ig6nuff132omqik1nv",
      IDENTITY_POOL_ID: "us-east-1:645847ed-600d-4748-a2cf-e2df41d9ebed",
    },
  };
  
  export default config;