import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

const ComponentDisplayBox = (props) => {
    let hasOptions = props.hasOptions || false;
    let uid = props.uid || null;
    let addHnArgs = props.addHnArgs || [];
    return (
        <div className="p-4 mb-3 bg-light rounded">
            <h4 className="fst-italic">{props.title}</h4>
            <p className="mb-0">{props.description}</p>
            <Form className="mt-3 text-right">
                { hasOptions &&
                    <Button
                        className="px-5 rounded-pill btn-info mr-3"
                        onClick={() => {props.optionsHn.setModalShow(true); props.optionsHn.setComponent(props.optionsHn.component)}}>Options
                    </Button>
                }
                <Button className="px-5 rounded-pill" data-cid={uid} onClick={(e) => props.addHn(e, ...addHnArgs)}>Add</Button>
            </Form>
        </div>
    );
}

export { ComponentDisplayBox }
