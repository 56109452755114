import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';


function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteImage', payload: id })
  sendMessage({ type: 'editor/deleteImage', payload: id });
}

function updateText(event) {
  let id = event.target.getAttribute('data-cid')
  let p = {
    _uid: id, 
    component: "Image",
    imgURL: event.target.value
  };

  store.dispatch({ type: 'editor/updateImage', payload: p })
  sendMessage({ type: 'editor/updateImage', payload: p });
}

export default function Image(props) {
  const [text, setText] = useState(props.data.text);

	return (
        <div className="p-4 mb-3 bg-light rounded">
            <h4 className="fst-italic">Image</h4>
            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <Form className="mt-3 text-right">
				<Form.Group>
					<Form.Control value={text} type="textarea" onChange={(e) => {updateText(e); setText(e.target.value)}} data-cid={props.data._uid} />
				</Form.Group>
				<Button className="px-5 rounded-pill" data-cid={props.data._uid} onClick={handleDelete}>Delete</Button>
            </Form>
        </div>
	);
}