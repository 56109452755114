import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "./libs/errorLib";
import "./_app.scss";
import logo from './imgs/logo-white-2.jpg';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();
  const pathname = window.location.pathname;
  
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
  
    userHasAuthenticated(false);
    history.push("/login");
  }
  
  return (
    !isAuthenticating && (
      <div className="App container-fluid px-0">
        { (pathname !== '/phone-frame') ? (
            <Navbar collapseOnSelect expand="md" className="mb-3 main-nav">
              <LinkContainer to="/">
                <Navbar.Brand className="font-weight-bold text-muted">
                  <img alt="logo" className="w-25" src={logo} />
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Nav activeKey={window.location.pathname}>
                  {isAuthenticated ? (
                    <>
                      <LinkContainer to="/settings">
                        <Nav.Link className="text-white">Settings</Nav.Link>
                      </LinkContainer>
                      <Nav.Link  className="text-white" onClick={handleLogout}>Logout</Nav.Link>
                    </>
                  ) : (
                    <>
                      <LinkContainer to="/signup">
                        <Nav.Link className="text-white">Signup</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/login">
                        <Nav.Link className="text-white">Login</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          ) : (
            <div></div>
          )}
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );

}

export default App;