import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Shadows(props) {
    return (
        <div key="inline-radio" className="mb-3">
            <Container className="bg-white">
                <h4>Shadows</h4>
                <Row>
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light shadow-none">No shadow</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-white shadow-sm">Small shadow</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-white shadow">Regular shadow</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-white shadow-lg">Large shadow</div> </Col> 
                </Row>
                <Row>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="None" name="group1" type="radio" id={"inline-radio-1"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Small" name="group1" type="radio" id={"inline-radio-2"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Regular" name="group1" type="radio" id={"inline-radio-3"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Large" name="group1" type="radio" id={"inline-radio-4"} /> </Col>
                </Row>
            </Container>
        </div>
    )
}