import React, { useReducer } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';
import { handleInsert as handleInsertJumbotron } from "../design/Jumbotron"
import { ComponentDisplayBox } from '../../containers/utils/ComponentDisplayBox'
import LayoutControls from './LayoutControls'
import TextAreaFormLayout from './forms/TextAreaFormLayout'

function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteJumbotron', payload: id })
  sendMessage({ type: 'editor/deleteJumbotron', payload: id });
}

function updateStoreAndUI(state, data) {
    console.log(state);
    var p = {
        _uid: data._uid, 
        component: "Jumbotron",
        text: state.text,
        placeholder: state.placeholder,
        buttonText: state.buttonText
    };

    switch (data.key) {
        case 'text':
            state = { ...state, text: data.value }
            p = { ...p, text: data.value }
            break;
        case 'placeholder':
            state = { ...state, placeholder: data.value }
            p = { ...p, placeholder: data.value }
            break;
        case 'buttonText':
            state = { ...state, buttonText: data.value }
            p = { ...p, buttonText: data.value }
            break;
        default:
            throw new Error();
    }

    store.dispatch({ type: 'editor/updateJumbotron', payload: p })
    sendMessage({ type: 'editor/updateJumbotron', payload: p });

    return state;
}

function addNewComponent(destination, setModalShow, setComponent, uid) {
    switch (destination) {
        case 'above':
            setModalShow(true);
            setComponent(NewComponent('above', uid));
            break;
    
        case 'below':
            setModalShow(true);
            setComponent(NewComponent('below', uid));
            break;
        
        default:
            setModalShow(true);
            setComponent(NewComponent('below', uid));
            break;

  }
}

function NewComponent(position, uid) {
  return (
    <div className="d-flex flex-wrap">
        <ComponentDisplayBox
            title="Main Header"
            description="Generate more leads with a professional landing page."
            addHnArgs={[position]}
            uid={uid}
            addHn={handleInsertJumbotron}/>
    </div>
  );
}

export default function Jumbotron(props) {
    const [state, dispatch] = useReducer(updateStoreAndUI, props.data);

	return (
        <div className="p-4 mb-3 w-100 bg-light rounded">
            <h4 className="fst-italic">Jumbotron</h4>
            <p className="mb-0">
                Your landing page is how you introduce yourself and/or businees to new users. Keep your text here short and to the
                point.
            </p>
            <Form className="mt-3 text-right">
                <Form.Group>
                    <Form.Label className="text-left d-block">Main text</Form.Label>
                    <Form.Control value={state.text} type="textarea" onChange={(e) => {dispatch({key: 'text', value: e.target.value, _uid: props.data._uid})}} data-cid={props.data._uid} />
                    <LayoutControls controlType="inputText" state={state} data={props.data} dispatch={dispatch}/>
                    <TextAreaFormLayout />
                    <Form.Label className="text-left d-block">Placeholder</Form.Label>
                    <Form.Control value={state.placeholder} type="textarea" onChange={(e) => {dispatch({key: 'placeholder', value: e.target.value, _uid: props.data._uid})}} data-cid={props.data._uid} />
                    <Form.Label className="text-left d-block">Button Text</Form.Label>
                    <Form.Control value={state.buttonText} type="textarea" onChange={(e) => {dispatch({key: 'buttonText', value: e.target.value, _uid: props.data._uid})}} data-cid={props.data._uid} />
                </Form.Group>
                <Button className="mr-5 px-5 rounded-pill" onClick={() => {addNewComponent('above', props.setModalShow, props.setComponent, props.data._uid)}}>Add new Component Above</Button>
                <Button className="mr-5 px-5 rounded-pill" onClick={() => {addNewComponent('below', props.setModalShow, props.setComponent, props.data._uid)}}>Add new Component Below</Button>
                <Button className="px-5 rounded-pill" data-cid={props.data._uid} onClick={handleDelete}>Delete</Button>
            </Form>

        </div>
	);
}