import React from "react";

const FooUI = (props) => (
  <div className="fooUI">
    <hr />
    Hi I'm a Foo component with the headline:
    <h2>{props.data.headline}</h2>
  </div>
);

export default FooUI;
