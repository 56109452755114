import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

export default function VerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.heading} 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.description}</p>
          { props.component }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}
