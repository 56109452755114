import React, { useState } from "react";
import store from "../libs/store"
import { phoneUI } from "../libs/buildComponentLib"
import "./css/basic.css"
//import "./css/color-palette.scss"

window.addEventListener("message", (event) => {
    try {
        if (event.origin !== "http://localhost:3000")
          return;
      
        store.dispatch(event.data);
    } catch(e) { console.log("An error occured: Check the following: Are you running a VPN (e.g. ExpressVPN)?",e) }
}, false);

export default function PhoneContent(props) {
    const [phoneUIComponents, setPhoneUIComponents] = useState(store.getState());
    store.subscribe(() => {
        setPhoneUIComponents(store.getState())
    })

    return (
        <div className={`main-iphone ${phoneUIComponents.styles.colorPalette}`}> 
            {phoneUIComponents.content.body.map(x=> phoneUI(x)) }
        </div>
    );
};
