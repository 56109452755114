import React from "react";
import Accordion from "react-bootstrap/Accordion"
import Borders from "./utilities/borders";
import Shadows from "./utilities/shadows";

function displayControls(controlType) {
    switch(controlType) {
        case 'inputText':
            return [<Borders />, <Shadows />]
        default:
            return []
    }
}

export default function LayoutControls(props) {
    return (
        <React.Fragment>
            <h3>Layout</h3>
            {
                displayControls(props.controlType).map((control, index) => (
                    <Accordion>
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header>Accordion Item { index }</Accordion.Header>
                            <Accordion.Body>{ control }</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))
            }
            {/* <Form.Label className="text-left d-block">Left</Form.Label>
            <Form.Control
                value={props.state.alignment}
                type="radio"
                onChange={(e) => {
                    props.dispatch({key: 'alignment', value: 'left', _uid: props.data._uid})}}
                data-cid={props.data._uid} />
            <Form.Label className="text-left d-block">Center</Form.Label> */}
        </React.Fragment>
    )
}