import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';
import { handleInsert as handleInsertNavBar } from "../design/NavBar"
import { ComponentDisplayBox } from '../../containers/utils/ComponentDisplayBox'


function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteNavBar', payload: id })
  sendMessage({ type: 'editor/deleteNavBar', payload: id });
}

function updateText(event) {
  let id = event.target.getAttribute('data-cid')
  let p = {
    _uid: id, 
    component: "NavBar",
    text: event.target.value
  };

  store.dispatch({ type: 'editor/updateNavBar', payload: p })
  sendMessage({ type: 'editor/updateNavBar', payload: p });
}

function addNewComponent(destination, setModalShow, setComponent, uid) {
    switch (destination) {
        case 'above':
            setModalShow(true);
            setComponent(NewComponent('above', uid));
            break;
    
        case 'below':
            setModalShow(true);
            setComponent(NewComponent('below', uid));
            break;
        
        default:
            setModalShow(true);
            setComponent(NewComponent('below', uid));
            break;

  }
}

function NewComponent(position, uid) {
  return (
    <div className="d-flex flex-wrap">
        <ComponentDisplayBox
            title="Navigation Bar"
            description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            addHnArgs={[position]}
            uid={uid}
            addHn={handleInsertNavBar}/>
        {/* <ComponentDisplayBox
            title="Header"
            description="Your header Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            hasOptions={true}
            optionsHn={{ "setModalShow" : setModalShow, "setComponent": setComponent, "component": HeaderDesign}}
            addHn={handleAddH1}/>
        <ComponentDisplayBox
            title="Online Course"
            description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            onClickHn={handleAddOnlineCourse}/>
        <ComponentDisplayBox
            title="Text Area"
            description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            addHn={handleAddTextArea}/>
        <ComponentDisplayBox
            title="Image"
            description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            addHn={handleAddImage}/>
        <ComponentDisplayBox
            title="Video"
            description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            addHn={handleAddVideo}/> */}
    </div>
  );
}

export default function NavBar(props) {
    const [text, setText] = useState(props.data.text);

	return (
        <div className="p-4 mb-3 bg-light rounded">
            <h4 className="fst-italic">Navigation Bar</h4>
            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <Form className="mt-3 text-right">
                <Form.Group>
                  <Form.Control value={text} type="textarea" onChange={(e) => {updateText(e); setText(e.target.value)}} data-cid={props.data._uid} />
                </Form.Group>
                <Button className="mr-5 px-5 rounded-pill" onClick={() => {addNewComponent('above', props.setModalShow, props.setComponent, props.data._uid)}}>Add new Component Above</Button>
                <Button className="mr-5 px-5 rounded-pill" onClick={() => {addNewComponent('below', props.setModalShow, props.setComponent, props.data._uid)}}>Add new Component Below</Button>
                <Button className="px-5 rounded-pill" data-cid={props.data._uid} onClick={handleDelete}>Delete</Button>
            </Form>
        </div>
	);
}