import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';
import { v4 as uuidv4 } from 'uuid';


function handleAddH1(event) {
    event.preventDefault();
    handleAdd("header-1");
}

function handleAddH2(event) {
    event.preventDefault();
    handleAdd("header-2");
}

function handleAddH3(event) {
    event.preventDefault();
    handleAdd("header-3");
}

function handleAddH4(event) {
    event.preventDefault();
    handleAdd("header-4");
}

function handleAddH5(event) {
    event.preventDefault();
    handleAdd("header-5");
}

function handleAddH6(event) {
    event.preventDefault();
    handleAdd("header-6");
}


function handleAdd(style) {
    let p = {
        _uid: uuidv4(), 
        style: style, 
        component: "Header",
        text: "Header Editor"
    };
    store.dispatch({ type: 'editor/addHeader', payload: p })
    sendMessage({ type: 'editor/addHeader', payload: p });
}

export { handleAddH1 }

export default function Header() {
  return (
    <div class="p-4 mb-3 bg-light rounded">
        <Form>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h1 className="ml-3">Header 1</h1></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH1}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h2 className="ml-3">Header 2</h2></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH2}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h3 className="ml-3">Header 3</h3></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH3}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h4 className="ml-3">Header 4</h4></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH4}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h5 className="ml-3">Header 5</h5></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH5}>Add</Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h6 className="ml-3">Header 6</h6></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAddH6}>Add</Button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
  );
}