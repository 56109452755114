import React from "react";
import NavBarUI from "./ui/NavBar"

// Left sidebar nav
// Video + Video Listing
// Merch Section
// Document Download
// TextArea

const OnlineCourseUI = (props) => (
  <div className="c-online-course">
    <NavBarUI data={{ "text": props.data.navText }} />
    <h2>{props.data.text}</h2>
  </div>
);

export default OnlineCourseUI;