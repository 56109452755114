import React, { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import "./Home.css";
import Phone from "./Phone";
import Builder from "./Builder"


export default function Home() {
  const [, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const notes = await loadNotes();
        setNotes(notes);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadNotes() {
    return API.get("notes", "/notes");
  }
  
  function renderLanderEditor() {
    return (
      <div className="lander">
        <h1>Login</h1>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mx-5">
        <div className="Home me-5">
          {isAuthenticated ? <Builder /> : renderLanderEditor()}
        </div>
        <div>
          <Phone />
        </div>
      </div>
    </React.Fragment>
  );
}