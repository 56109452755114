import React from "react";
import { userEditingUI } from "../libs/buildComponentLib"
import VerticallyCenteredModal from './VerticallyCenteredModal'

export default function Editor(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [component, setComponent] = React.useState(null);
    return(
        <React.Fragment>
            <div className="mb-5 py-4 px-5 design-box rounded-lg shadow-lg">
                <nav>
                    <div className="nav nav-tabs nav-design-tabs" role="tablist">
                        <a className="nav-link active" id="nav-basic-tab" data-bs-toggle="tab" href="#nav-editor" role="tab" aria-controls="nav-basic" aria-selected="true">
                            <span className="fa-stack">
                                <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                                <i className="fas fa-palette fa-stack-1x fa-inverse icon-bg"></i>
                            </span>Editor
                        </a>
                    </div>
                </nav>
                <div className="tab-content mt-5" id="nav-eidtor">
                    <div className="d-flex flex-row flex-wrap tab-pane fade show active" id="nav-editor" role="tabpanel" aria-labelledby="nav-editor-tab">
                        { props.uiComponents.content.body.map(x=> userEditingUI(x, setModalShow, setComponent)) }
                    </div>
                </div>
            </div>

            <VerticallyCenteredModal
                heading="Component"
                description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                show={modalShow}
                onHide={() => setModalShow(false)}
                component={component}
            />

        </React.Fragment>
    );
}
