import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsArrowRepeat } from "react-icons/bs";
import store from "../libs/store"
import { v4 as uuidv4 } from 'uuid';
import { sendMessage } from '../libs/utils';

function handleSubmit(event) {
  // TODO store
  event.preventDefault();
}

function handleAdd(event) {
  event.preventDefault();

  let p = {
    _uid: uuidv4(), 
    component: "foo",
    headline: "Default Foo"
  };
  store.dispatch({ type: 'editor/addFoo', payload: p })
  sendMessage({ type: 'editor/addFoo', payload: p });
}

function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteFoo', payload: id })
  sendMessage({ type: 'editor/deleteFoo', payload: id });
}

const Foo = (props) => (
  <Form onSubmit={handleSubmit}>
    <div className="">
      <hr />
      Hi I'm a Foo component with the headline:
      <h2>{props.data.headline}</h2>
      <Button onClick={handleAdd}>
        <BsArrowRepeat />
        "Add"
      </Button>
      <Button data-cid={props.data._uid} onClick={handleDelete}>
        <BsArrowRepeat />
        "Delete"
      </Button>
      <Button type="submit">
        <BsArrowRepeat />
        "Save"
      </Button>
    </div>
  </Form>
);

export default Foo;