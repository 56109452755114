import React from "react";

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import bgimage from '../../imgs/bg-masthead.jpeg'
import '../css/jumbotron.scss'

const JumbotronUI = (props) => (
    <div className="jumbotron-ui" style={{ paddingTop: '8rem', paddingBottom: '8rem', backgroundImage: `url(${bgimage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}> 
        <h1>{ props.data.text }</h1>
        <InputGroup className="mb-3">
            <FormControl
                placeholder={props.data.placeholder}
                aria-label={props.data.placeholder}
            />
            <InputGroup.Append>
            <Button className="btn btn-primary" type="button">{props.data.buttonText}</Button>
            </InputGroup.Append>
        </InputGroup>
    </div>
);

export default JumbotronUI;