import React from "react";
import '../css/containers/_designBox.scss';
import BasicTemplate from './BasicTemplate';
import ComponentLibrary from './ComponentLibrary';
import ElementLibrary from './ElementLibrary';

export default function TemplateBox(props) {
    return(
        <React.Fragment>
            <div className="mb-5 py-4 px-5 design-box rounded-lg shadow-lg">
                <nav>
                    <div className="nav nav-tabs nav-design-tabs" role="tablist">
                        <a className="nav-link active" id="nav-basic-tab" data-bs-toggle="tab" href="#nav-basic" role="tab" aria-controls="nav-basic" aria-selected="true">
                            <span className="fa-stack">
                                <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                                <i className="fas fa-palette fa-stack-1x fa-inverse icon-bg"></i>
                            </span>Basic
                        </a>
                        <a className="nav-link" id="nav-online-course-tab" data-bs-toggle="tab" href="#nav-online-course" role="tab" aria-controls="nav-online-course" aria-selected="false">
                            <span className="fa-stack">
                                <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                                <i className="fas fa-wrench fa-stack-1x fa-inverse icon-bg"></i>
                            </span>Online Course
                        </a>
                        <a className="nav-link" id="nav-online-shop-tab" data-bs-toggle="tab" href="#nav-online-shop" role="tab" aria-controls="nav-online-shop" aria-selected="false">
                            <span className="fa-stack">
                                <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                                <i className="fas fa-wrench fa-stack-1x fa-inverse icon-bg"></i>
                            </span>Online Shop
                        </a>
                    </div>
                </nav>
                <div className="tab-content mt-5" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-basic" role="tabpanel" aria-labelledby="nav-basic-tab">
                        <BasicTemplate />
                    </div>
                    <div className="tab-pane fade" id="nav-online-course" role="tabpanel" aria-labelledby="nav-online-course-tab">
                        <ComponentLibrary />
                    </div>
                    <div className="tab-pane fade" id="nav-online-shop" role="tabpanel" aria-labelledby="nav-online-shop-tab">
                        <ElementLibrary />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

