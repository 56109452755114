// import { handleAddH1 } from '../../components/design/Header'
import { handleAdd as handleNavBarAdd } from '../../components/design/NavBar'
// import { handleAdd as handleTextAreaAdd } from '../../components/design/TextArea'
// import { handleAdd as handleImageAdd } from '../../components/design/Image'
import { handleAdd as handleJumbotronAdd } from '../../components/design/Jumbotron'

export default function handleAddBasic(event) {
    handleNavBarAdd(event);
    handleJumbotronAdd(event);
    // handleAddH1(event);
    // handleTextAreaAdd(event);
    // handleImageAdd(event);
}