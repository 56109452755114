import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';
import { v4 as uuidv4 } from 'uuid';


function handleAdd(event) {
    console.log("Addig image...")
    event.preventDefault();

    let p = {
        _uid: uuidv4(), 
        component: "Image",
        imgURL: "https://cdn.slashgear.com/wp-content/uploads/2018/12/spacex_cc0_unsplash.jpg" 
    };
    store.dispatch({ type: 'editor/addImage', payload: p })
    sendMessage({ type: 'editor/addImage', payload: p });
}

export { handleAdd }

export default function Image() {
  return (
    <div class="p-4 mb-3 bg-light rounded">
        <Form>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h1 className="ml-3">Image</h1></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAdd}>Add</Button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
  );
}