import React from "react";
import Image from "react-bootstrap/Image";

const ZipImage = {}

ZipImage.Image = (props) => (
  <div className="img-container">
    <Image src={props.data.imgURL} rounded />
  </div>
);

export default ZipImage;