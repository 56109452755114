import initialState from "../data/components";

function findInsertionPoint(state, action) {
  let insertAtIndex = state.content.body.findIndex(el => action.id === el._uid)
  let index = insertAtIndex !== -1 ? insertAtIndex : 0;
  var position = action.position || 'below';

  switch(position) {
    case 'below':
      index = index+1; 
      break;
    default:
      break;
  }

  return index
}
  
// Use the initialState as a default value
export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'editor/addAccordion': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateAccordion': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteAccordion': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addAlert': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateAlert': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteAlert': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/updateLayout': {
      return {
        ...state,  // the existing state data
        styles: { // the updated state
            layout: action.payload,
            colorPalette: state.styles.colorPalette 
        }
      }
    }
    case 'editor/updateColorPalette': {
      return {
        ...state,  // the existing state data
        styles: { // the updated state
            colorPalette: action.payload
        }
      }
    }
    case 'editor/addFoo': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/deleteFoo': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addBar': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/deleteBar': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addTextArea': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/deleteTextArea': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/updateTextArea': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/addImage': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateImage': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteImage': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addHeader': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateHeader': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteHeader': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addNavBar': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/insertNavBar': {
      let index = findInsertionPoint(state, action)
      
      state.content.body.splice(index, 0, action.payload)

      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body]
        }
      }
    }
    case 'editor/updateNavBar': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteNavBar': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addJumbotron': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/insertJumbotron': {
      let index = findInsertionPoint(state, action)
      
      state.content.body.splice(index, 0, action.payload)

      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body]
        }
      }
    }
    case 'editor/updateJumbotron': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteJumbotron': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addOnlineCourse': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateOnlineCourse': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteOnlineCourse': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    case 'editor/addVideo': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body, action.payload]
        }
      }
    }
    case 'editor/updateVideo': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.map(el => el._uid === action.payload._uid ? action.payload : el)]
        }
      }
    }
    case 'editor/deleteVideo': {
      return {
        ...state,  // the existing state data
        content: { // the updated state
            body: [...state.content.body.filter(x => x._uid !== action.payload)]
        }
      }
    }
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}