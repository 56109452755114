import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import store from "../libs/store"
import { sendMessage } from '../libs/utils';
import { v4 as uuidv4 } from 'uuid';


function handleAddDefault(event) {
    event.preventDefault();
    handleAdd("accordion-default");
}

function handleAddLine(event) {
    event.preventDefault();
    handleAdd("accordion-line");
}

function handleAddWithIcon(event) {
    event.preventDefault();
    handleAdd("accordion-with-icon");
}

function handleAdd(style) {
    let p = {
        _uid: uuidv4(), 
        style: style, 
        component: "AccordionEditor",
        text: "Accordion Editor"
    };
    store.dispatch({ type: 'editor/addAccordion', payload: p })
    sendMessage({ type: 'editor/addAccordion', payload: p });
}

export default function Accordion() {
  return (
    <Card className="w-25 mr-1 mb-1">
        <Card.Header>Accordion</Card.Header>
        <Card.Body>
            <Form>
                <div>
                    <Button onClick={handleAddDefault}>Add</Button>
                    <span className="ml-3">Accordion Default</span>
                </div>
                <div>
                    <Button onClick={handleAddLine}>Add</Button>
                    <span className="ml-3">Accordion Line</span>
                </div>
                <div>
                    <Button onClick={handleAddWithIcon}>Add</Button>
                    <span className="ml-3">Accordion with Icon</span>
                </div>
            </Form>
        </Card.Body>
    </Card>
  );
}