import React from "react";
import "./Phone.css";
import "./PhoneContainer.css";


export default function Phone() {
    return (
        <div className="marvel-device iphone-x phone sticky">
            <div className="notch">
                {/* <div className="camera"></div> */}
                <div className="speaker"></div>
            </div>
            <div className="top-bar"></div>
            {/*<div className="sleep"></div>*/}
            <div className="bottom-bar"></div>
            {/* <div className="volume"></div> */}
            <div className="overflow">
                <div className="shadow shadow--tr"></div>
                <div className="shadow shadow--tl"></div>
                <div className="shadow shadow--br"></div>
                <div className="shadow shadow--bl"></div>
            </div>
            <div className="inner-shadow"></div>
            <div className="screen">
                <iframe id="phoneFrame" title="phone" src="/phone-frame" />
            </div>
        </div>
    );
};