import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import store from "../libs/store"
import { v4 as uuidv4 } from 'uuid';
import { sendMessage } from '../libs/utils';

function handleAdd(event) {
  event.preventDefault();

  let p = {
    _uid: uuidv4(), 
    component: "bar",
    headline: "Default Bar"
  };
  store.dispatch({ type: 'editor/addBar', payload: p })
  sendMessage({ type: 'editor/addBar', payload: p });
}

function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteBar', payload: id })
  sendMessage({ type: 'editor/deleteBar', payload: id });
}

const Bar = (props) => (
  <div className="bar">
    <hr />
    Hi I'm a Bar component with the headline:
    <h2>{props.data.headline}</h2>
    <Button onClick={handleAdd}>
      <BsArrowRepeat />
      "Add"
    </Button>
    <Button data-cid={props.data._uid} onClick={handleDelete}>
      <BsArrowRepeat />
      "Delete"
    </Button>
    <Button type="submit">
      <BsArrowRepeat />
      "Save"
    </Button>
  </div>
);

export default Bar;