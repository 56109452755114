import { createStore } from 'redux'
import rootReducer from './reducer'
import data from "../data/components";

let preloadedState = JSON.parse(JSON.stringify({}));
const persistedData = data;

if (persistedData) {
  preloadedState = JSON.parse(JSON.stringify(data))
}

const store = createStore(rootReducer, preloadedState)
export default store;