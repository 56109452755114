const data = {
    styles: {
      layout: "basic",
      colorPalette: "sleak"
    },
    content: {
      body: [
        // {
        //   _uid: "gJZoSLkfZV",
        //   component: "Image",
        //   imgURL: "https://cdn.slashgear.com/wp-content/uploads/2018/12/spacex_cc0_unsplash.jpg"
        // },
        // {
        //   _uid: "X1JAfdsZxy",
        //   component: "TextArea",
        //   text: "Dual Falcon 9 rockets landing in Florida"
        // }
      ]
    }
};

export default data;