import React from "react";

const BarUI = (props) => (
  <div className="barUI">
    <hr />
    Hi I'm a Bar component with the headline:
    <h2>{props.data.headline}</h2>
  </div>
);

export default BarUI;