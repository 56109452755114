import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import store from "../libs/store"
import { sendMessage } from '../libs/utils';


function handleDelete(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/deleteOnlineCourse', payload: id })
  sendMessage({ type: 'editor/deleteOnlineCourse', payload: id })
}

function update(event, navText, text) {
  let id = event.target.getAttribute('data-cid')
  let p = {
    _uid: id, 
    component: "OnlineCourse",
    text: text,
    navText: navText 
  };

  serializeObject();
  store.dispatch({ type: 'editor/updateOnlineCourse', payload: p })
  sendMessage({ type: 'editor/updateOnlineCourse', payload: p })
}

function serializeObject () {
	var obj = {};

  let el = document.getElementById('test');
  let children = el.children;
  let arr = Array.prototype.slice.call(children);

  arr.reduce((c) => {
    obj[c.name] = c.value
    return obj;
  });

  console.log("Obj", obj)

	return obj;
};

export default function OnlineCourse(props) {
  const [text, setText] = useState(props.data.text);
  const [navText, setNavText] = useState(props.data.navText);

  return (
    <Card>
      <Card.Header>Online Course</Card.Header>
      <Card.Body>
        <Form.Group id="test">
          <Form.Control name="navText" value={navText} type="textarea" onChange={(e) => {setNavText(e.target.value); update(e, e.target.value, text)}} data-cid={props.data._uid} />
          <Form.Control name="text" value={text} type="textarea" onChange={(e) => {setText(e.target.value); update(e, navText, e.target.value)}} data-cid={props.data._uid} />
        </Form.Group>
        <Button data-cid={props.data._uid} onClick={handleDelete}>Delete</Button>
      </Card.Body>
    </Card>
  );
}