import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Borders(props) {
    return (
        <div key="inline-radio" className="mb-3">
            <Container className="bg-white">
                <h4>Borders</h4>
                <Row>
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light border">Border All</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light border-top">Border Top</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light border-end">Border Right</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light border-bottom">Border Bottom</div> </Col> 
                    <Col className="text-center" lg="2"> <div className="mx-2 my-4 p-5 bg-light border-start">Border Left</div> </Col> 
                </Row>
                <Row>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="All" name="group1" type="radio" id={"inline-radio-1"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Top" name="group1" type="radio" id={"inline-radio-2"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Right" name="group1" type="radio" id={"inline-radio-3"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Bottom" name="group1" type="radio" id={"inline-radio-4"} /> </Col>
                    <Col className="mb-2 text-center" lg="2"> <Form.Check inline label="Left" name="group1" type="radio" id={"inline-radio-5"} /> </Col>
                </Row>
            </Container>
        </div>
    )
}