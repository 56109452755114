import React from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import "../css/containers/_colorsLibrary.scss";
import store from "../libs/store";
import { sendMessage } from '../libs/utils';
import '../css/core/_variables.scss';
import dummycolortest from '../imgs/dummy-color-test.jpg';

function updateColorPalette(event) {
  event.preventDefault();
  let id = event.target.getAttribute('data-cid')
  store.dispatch({ type: 'editor/updateColorPalette', payload: id })
  sendMessage({ type: 'editor/updateColorPalette', payload: id })
}

export default function ColorsLibrary(props) {
    return(
        <React.Fragment>
            <Image className="w-100" src={dummycolortest} rounded />
            <ListGroup horizontal>
                <ListGroup.Item className="r-50">Red 50</ListGroup.Item>
                <ListGroup.Item className="r-100">100</ListGroup.Item>
                <ListGroup.Item className="r-200">200</ListGroup.Item>
                <ListGroup.Item className="r-300">300</ListGroup.Item>
                <ListGroup.Item className="r-400">400</ListGroup.Item>
                <ListGroup.Item className="r-500">500</ListGroup.Item>
                <ListGroup.Item className="r-600">600</ListGroup.Item>
                <ListGroup.Item className="r-700">700</ListGroup.Item>
                <ListGroup.Item className="r-800">800</ListGroup.Item>
                <ListGroup.Item className="r-900">900</ListGroup.Item>
                <ListGroup.Item className="r-A100">A100</ListGroup.Item>
                <ListGroup.Item className="r-A200">A200</ListGroup.Item>
                <ListGroup.Item className="r-A400">A400</ListGroup.Item>
                <ListGroup.Item className="r-A700">A700</ListGroup.Item>
            </ListGroup>
            <Button data-cid='future' onClick={updateColorPalette}>Apply</Button>
            <Button data-cid='sleak' onClick={updateColorPalette}>Sleak</Button>
        </React.Fragment>
    )
}
