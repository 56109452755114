import React from "react";

const VideoUI = (props) => {
  return (
    <div className="c-video">
        <iframe title="somevid" width="560" height="315" src={props.data.text} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
    </div>
  );
}

export default VideoUI; 
