import React, { useState } from "react";
import Editor from "./Editor"
import DesignBox from "./DesignBox"
import TemplateBox from "./TemplateBox"
import store from "../libs/store"
import '../css/containers/_builder.scss'

export default function Builder(props) {
    const [editingUIComponents, setEditingComponents] = useState(store.getState());

    store.subscribe(() =>
        setEditingComponents(store.getState())
    )

    return(
        <React.Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="template-tab" data-bs-toggle="tab" data-bs-target="#template" type="button" role="tab" aria-controls="template" aria-selected="true">
                        <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                            <i className="fas fa-list-radio fa-stack-1x fa-inverse icon-bg"></i>
                        </span>Template
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="design-tab" data-bs-toggle="tab" data-bs-target="#design" type="button" role="tab" aria-controls="design" aria-selected="false">
                        <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                            <i className="fas fa-palette fa-stack-1x fa-inverse icon-bg"></i>
                        </span>Design This
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="editor-tab" data-bs-toggle="tab" data-bs-target="#editor" type="button" role="tab" aria-controls="editor" aria-selected="false">
                        <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x icon-bg-stack"></i>
                            <i className="fas fa-wrench fa-stack-1x fa-inverse icon-bg"></i>
                        </span>Editor
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="template" role="tabpanel" aria-labelledby="template-tab">
                    <TemplateBox />
                </div>
                <div className="tab-pane fade" id="design" role="tabpanel" aria-labelledby="design-tab">
                    <DesignBox />
                </div>
                <div className="tab-pane fade" id="editor" role="tabpanel" aria-labelledby="editor-tab">
                    <Editor uiComponents={ editingUIComponents } />
                </div>
            </div>
        </React.Fragment>
    );
}