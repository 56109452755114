import React from "react";
import Foo from "../components/Foo";
import FooUI from "../components/FooUI";
import AccordionEditor from "../components/AccordionEditor";
import AccordionUI from "../components/AccordionUI";
import AlertEditor from "../components/AlertEditor";
import AlertUI from "../components/AlertUI";
import Bar from "../components/Bar";
import BarUI from "../components/BarUI";
import TextAreaEditor from "../components/editor/TextArea"
import TextAreaUI from "../components/ui/TextArea"
import ImageEditor from "../components/editor/Image"
import ZipImageUI from "../components/ui/Image"
import HeaderEditor from "../components/editor/Header";
import HeaderUI from "../components/ui/Header";
import PhoneContent from "../components/PhoneContent"
import NavBarEditor from "../components/editor/NavBar"
import NavBarUI from "../components/ui/NavBar"
import OnlineCourse from "../components/OnlineCourse"
import OnlineCourseUI from "../components/OnlineCourseUI"
import Video from "../components/Video"
import VideoUI from "../components/VideoUI"
import JumbotronEditor from "../components/editor/Jumbotron"
import JumbotronUI from "../components/ui/Jumbotron"

const Components = {
  AccordionEditor: AccordionEditor,
  AlertEditor: AlertEditor,
  foo: Foo,
  bar: Bar,
  TextArea: TextAreaEditor,
  Image: ImageEditor,
  PhoneContent: PhoneContent,
  Header: HeaderEditor,
  NavBar: NavBarEditor,
  Jumbotron: JumbotronEditor,
  OnlineCourse: OnlineCourse,
  Video: Video
};

const UIComponents = {
  AccordionEditor: AccordionUI,
  AlertEditor: AlertUI,
  foo: FooUI,
  bar: BarUI,
  TextArea: TextAreaUI,
  Image: ZipImageUI.Image,
  Header: HeaderUI,
  NavBar: NavBarUI,
  Jumbotron: JumbotronUI,
  OnlineCourse: OnlineCourseUI,
  Video: VideoUI
};

const userEditingUI = function (componentData, setModalShow, setComponent, setPosition) {
  return (
		React.createElement(Components[componentData.component], {
			data: componentData,
			key: componentData._uid,
      setModalShow: setModalShow,
      setComponent: setComponent,
      setPosition: setPosition
		})
  )
}

const phoneUI = function (componentData) {
  return React.createElement(UIComponents[componentData.component], {
    data: componentData,
    key: componentData._uid
  });
}

export { userEditingUI, phoneUI };