import React from "react";
import Button from 'react-bootstrap/Button';
import VerticallyCenteredModal from './VerticallyCenteredModal'
import Accordion from "../components/Accordion";
import Alert from "../components/Alert"
import '../css/containers/_elementLibrary.scss';


export default function ElementLibrary() {
    const [modalShow, setModalShow] = React.useState(false);
    const [component, setComponent] = React.useState(false);
    return (
        <React.Fragment>
            <div className="element-library d-flex flex-wrap">
                <div className="row g-0 flex-fill">
                    <div className="col-sm-6 col-lg-3">
                        <ul className="list-unstyled">
                            <li>
                                <Button variant="link" onClick={() => { setModalShow(true); setComponent(Accordion) }}><i className="far fa-plus-square fa-fw me-2"></i>Accoridian</Button>
                            </li>
                            <li> <a className="dropdown-item" href="elements-action-box.html"><i className="fas fa-paperclip fa-fw me-2"></i>Action box</a> </li>
                            <li>
                                <Button variant="link" onClick={() => { setModalShow(true); setComponent(Alert) }}><i className="fas fa-exclamation-triangle fa-fw me-2"></i>Alert</Button>
                            </li>
                            <li> <a className="dropdown-item" href="elements-animation.html"><i className="fas fa-radiation-alt fa-fw me-2"></i>Animation</a> </li>
                            <li> <a className="dropdown-item" href="elements-avatar.html"><i className="far fa-user fa-fw me-2"></i>Avatar</a> </li>
                            <li> <a className="dropdown-item" href="elements-blockquote.html"><i className="fas fa-quote-right fa-fw me-2"></i>Blockquote</a> </li>
                            <li> <a className="dropdown-item" href="elements-breadcrumbs.html"><i className="fas fa-angle-right fa-fw me-2"></i>breadcrumbs</a> </li>
                            <li> <a className="dropdown-item" href="elements-buttons.html"><i className="fas fa-link fa-fw me-2"></i>Buttons</a> </li>
                            <li> <a className="dropdown-item" href="elements-cards.html"><i className="far fa-window-restore fa-fw me-2"></i>Cards</a> </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <ul className="list-unstyled">
                            <li> <a className="dropdown-item" href="elements-clients.html"><i className="fas fa-user-tie fa-fw me-2"></i>Clients</a> </li>
                            <li> <a className="dropdown-item" href="elements-counter.html"><i className="fas fa-sort-numeric-up-alt fa-fw me-2"></i>Counter</a> </li>
                            <li> <a className="dropdown-item" href="elements-decorations.html"><i className="fas fa-spray-can fa-fw me-2"></i>decorations</a> </li>
                            <li> <a className="dropdown-item" href="elements-divider.html"><i className="fas fa-cut fa-fw me-2"></i>Divider</a> </li>
                            <li> <a className="dropdown-item" href="elements-feature-box.html"><i className="fas fa-newspaper fa-fw me-2"></i>Feature box</a> </li>
                            <li> <a className="dropdown-item" href="elements-forms.html"><i className="far fa-clipboard fa-fw me-2"></i>Forms</a> </li>
                            <li> <a className="dropdown-item" href="elements-forms-layout.html"><i className="fab fa-wpforms fa-fw me-2"></i>Forms layout</a> </li>
                            <li> <a className="dropdown-item" href="elements-google-map.html"><i className="fas fa-map fa-fw me-2"></i>Google Map</a> </li>
                            <li> <a className="dropdown-item" href="elements-hamburger-menu.html"><i className="fas fa-bars fa-fw me-2"></i>Hamburger Menus</a> </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <ul className="list-unstyled">
                            <li> <a className="dropdown-item" href="elements-list-group.html"><i className="fas fa-list fa-fw me-2"></i>list group</a> </li>
                            <li> <a className="dropdown-item" href="elements-modals.html"><i className="fas fa-search-plus fa-fw me-2"></i>Modals popup</a> </li>
                            <li> <a className="dropdown-item" href="elements-pagination.html"><i className="fas fa-ellipsis-h fa-fw me-2"></i>Pagination</a> </li>
                            <li> <a className="dropdown-item" href="elements-preloader.html"><i className="fas fa-spinner fa-spin fa-fw me-2"></i>Pre loaders</a> </li>
                            <li> <a className="dropdown-item" href="elements-process.html"><i className="fas fa-tasks fa-fw me-2"></i>Process (Work Step)</a> </li>
                            <li> <a className="dropdown-item" href="elements-progress.html"><i className="fas fa-grip-lines fa-fw me-2"></i>progress bar</a> </li>
                            <li> <a className="dropdown-item" href="elements-social-icons.html"><i className="fas fa-share-alt fa-fw me-2"></i>social icons</a> </li>
                            <li> <a className="dropdown-item" href="elements-tables.html"><i className="fas fa-table fa-fw me-2"></i>Tables</a> </li>
                            <li> <a className="dropdown-item" href="elements-tabs.html"><i className="fas fa-toggle-on fa-fw me-2"></i>Tabs and Navs</a> </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <ul className="list-unstyled">
                            <li> <a className="dropdown-item" href="elements-typing-headlines.html"><i className="fas fa-i-cursor blink-animation fa-fw me-2"></i>Typing headlines</a> </li>
                            <li> <a className="dropdown-item" href="elements-typography.html"><i className="fas fa-font fa-fw me-2"></i>Typography</a> </li>
                            <li> <a className="dropdown-item" href="elements-video.html"><i className="fas fa-file-video fa-fw me-2"></i>Video</a> </li>
                            <li> <a className="dropdown-item" href="elements-video-background.html"><i className="fas fa-photo-video fa-fw me-2"></i>Video background</a> </li>
                            <li className="dropdown-divider"></li>
                            <li className="dropdown-header mb-0">Plugins</li>
                            <li> <a className="dropdown-item" href="elements-glightbox.html"><i className="fas fa-expand fa-fw me-2"></i>GLightbox popup</a> </li>
                            <li> <a className="dropdown-item" href="elements-isotope.html"><i className="fas fa-sliders-h fa-fw me-2"></i>Isotope (Filters &amp; Masonry)</a> </li>
                            <li> <a className="dropdown-item" href="elements-tiny-slider.html"><i className="far fa-arrow-alt-circle-right fa-fw me-2"></i>Tiny slider</a> </li>
                        </ul>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="bg-dark p-5 pb-4 w-100 pattern-overlay-1">
                            <div className="row d-md-flex justify-content-center">
                                <h5 className="col-md-4 text-white mb-4">Discover Folio - It's easier than you might think.</h5>
                                <p className="col-md-3 text-white">Start right away to create your dream website hassle free. Grab yours now.</p>
                                <div className="col-md-3 text-md-end">
                                    <a className="btn btn-line btn-primary" href="elements-glightbox.html">Purchase Folio!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <VerticallyCenteredModal
                heading="Element"
                description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                show={modalShow}
                onHide={() => setModalShow(false)}
                component={component}
            />
        </React.Fragment>
    );
}