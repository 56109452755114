import React from "react";
import VerticallyCenteredModal from './VerticallyCenteredModal'
import HeaderDesign from "../components/design/Header"
import { handleAddH1 } from "../components/design/Header"
import { handleAdd as handleAddNavBar } from "../components/design/NavBar"
import { handleAdd as handleAddTextArea } from "../components/design/TextArea"
import { handleAdd as handleAddImage } from "../components/design/Image"
import { v4 as uuidv4 } from 'uuid';
import store from "../libs/store";
import { sendMessage } from '../libs/utils';
import { ComponentDisplayBox } from './utils/ComponentDisplayBox'

function handleAddOnlineCourse(event) {
    event.preventDefault();

    let p = {
        _uid: uuidv4(), 
        component: "OnlineCourse",
        navText: "New Course",
        text: "My Online Course"
    };
    store.dispatch({ type: 'editor/addOnlineCourse', payload: p });
    sendMessage({ type: 'editor/addOnlineCourse', payload: p });
}

function handleAddVideo(event) {
    event.preventDefault();

    let p = {
        _uid: uuidv4(), 
        component: "Video",
        text: "https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
    };
    store.dispatch({ type: 'editor/addVideo', payload: p })
    sendMessage({ type: 'editor/addVideo', payload: p });
}

export default function ComponentLibrary() {
    const [modalShow, setModalShow] = React.useState(false);
    const [component, setComponent] = React.useState(false);
    return (
        <React.Fragment>
            <div className="d-flex flex-wrap">
                <ComponentDisplayBox
                    title="Navigation Bar"
                    description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    addHn={handleAddNavBar}/>
                <ComponentDisplayBox
                    title="Header"
                    description="Your header Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    hasOptions={true}
                    optionsHn={{ "setModalShow" : setModalShow, "setComponent": setComponent, "component": HeaderDesign}}
                    addHn={handleAddH1}/>
                <ComponentDisplayBox
                    title="Online Course"
                    description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    onClickHn={handleAddOnlineCourse}/>
                <ComponentDisplayBox
                    title="Text Area"
                    description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    addHn={handleAddTextArea}/>
                <ComponentDisplayBox
                    title="Image"
                    description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    addHn={handleAddImage}/>
                <ComponentDisplayBox
                    title="Video"
                    description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    addHn={handleAddVideo}/>
            </div>

            <VerticallyCenteredModal
                heading="Component"
                description="Your nav bar Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                show={modalShow}
                onHide={() => setModalShow(false)}
                component={component}
            />

        </React.Fragment>
    );
}