import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import store from "../../libs/store"
import { sendMessage } from '../../libs/utils';
import { v4 as uuidv4 } from 'uuid';


function handleAdd(event, position) {
    event.preventDefault();

    let p = {
        _uid: uuidv4(), 
        component: "NavBar",
        text: "NavBar Editor"
    };
    store.dispatch({ type: 'editor/addNavBar', payload: p })
    sendMessage({ type: 'editor/addNavBar', payload: p });
}

function handleInsert(event, position) {
    event.preventDefault();

    position = position || 'below';
    let id = event.target.getAttribute('data-cid')

    let p = {
        _uid: uuidv4(), 
        component: "NavBar",
        text: "NavBar Editor"
    };
    store.dispatch({ type: 'editor/insertNavBar', payload: p, id: id, position: position })
    sendMessage({ type: 'editor/insertNavBar', payload: p, id: id, position: position });
}

export { handleAdd, handleInsert }

export default function NavBar() {
  return (
    <div class="p-4 mb-3 bg-light rounded">
        <Form>
            <div className="row">
                <div className="col-6">
                    <div className="mb-5"><h1 className="ml-3">Navigation Bar</h1></div>
                </div>
                <div className="col-6">
                    <div className="mb-5">
                        <Button
                            className="px-5 rounded-pill btn-primary mr-3 float-right"
                            onClick={handleAdd}>Add</Button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
  );
}